import React from "react"
import Footer from "./footer"

class Controller extends React.Component {
  render() {
    return <Footer />
  }
}

export default Controller
